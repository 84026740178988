(function ($) {
  'use strict';

  var instax = instax || {};
  var selectedTab = parseInt(localStorage.getItem('tab')) || 0
  var tabClass = ''
  var supportSelector = $('.faq-selector')
  var supportBtns = $('.support-btn')

  $.fn.extend({
    toggleHtml: function (a, b) {
      return this.html(this.html() == b ? a : b);
    }
  });

  function setVisible(selected) {
    Array.from(supportSelector).forEach((e, i) => {
      if ((i % 3) === selected) {
        e.style.display = 'block';
      } else {
        e.style.display = 'none';
      }
    })

    Array.from(supportBtns).forEach((e, i) => {
      if (i === selected) {
        e.classList.add('is-style-fill')
        e.classList.remove('is-style-outline')
      } else {
        e.classList.remove('is-style-fill')
        e.classList.add('is-style-outline')
      }
    })
  }

  $('.support-btn').click(function (e) {
    tabClass = Array.from(e.currentTarget.classList).filter(e => e.includes('active'))[0]
    selectedTab = (Array.from(supportBtns).indexOf(e.currentTarget))
    setVisible(selectedTab)
    localStorage.setItem('tab', selectedTab);
  });

  setVisible(selectedTab)

  $('.show-more-btn').click(function (e) {
    $(this).toggleClass('white-btn-caret-active');
    $(this).toggleHtml('Show More', 'Show Less');
    $(`#hidden-${e.currentTarget.dataset.showButton}`).toggleClass('show')
  })

  if (typeof localizedUrl !== 'undefined' && $('.contact-us a').length > 0) {
    $('.contact-us a')[0].href = localizedUrl + '/contact-us'
  }

}(jQuery))
